import { jsx as _jsx } from "react/jsx-runtime";
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useAppSelector } from './store/Hooks';
import { getThemeConfig } from './theme';
import store, { persistor } from './store';
import { Toast } from '../shared/ui/overlay/Toast';
import router from './routing/router';
const App = () => {
    const currentTheme = useAppSelector(state => state.app.theme);
    const theme = useMemo(() => getThemeConfig(currentTheme), [currentTheme]);
    return (_jsx(Provider, { store: store, children: _jsx(PersistGate, { loading: null, persistor: persistor, children: _jsx(ThemeProvider, { theme: theme, children: _jsx(EmotionThemeProvider, { theme: theme, children: _jsx(SnackbarProvider, { Components: {
                            success: Toast,
                            warning: Toast,
                            info: Toast,
                            error: Toast,
                        }, children: _jsx(RouterProvider, { router: router }) }) }) }) }) }));
};
export default App;
